<template>
  <div id="app">
    <Top/>
    <router-view/>
    <Footer/>
  </div>
</template>

<script>
  import Top from '@/components/top'
  import Footer from '@/components/footer'
  export default{
    components:{
      Top,
      Footer
    }
  }
</script>

<style lang="scss">
*{
  margin: 0;
  padding: 0;
}
Footer{
  position: fixed;
  bottom: 0;
}
</style>
