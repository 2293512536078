<template>
  <div>
    <div class="index">
      <div class="banner">
        <el-carousel indicator-position="outside">
          <el-carousel-item v-for="(item,index) in swiperImage[0].image_fir " :key="index">
            <img :src="item" alt="" style="height: auto;">
          </el-carousel-item>
        </el-carousel>
      </div>
      <div class="company_view">
        <div class="l">
          <div class="company-name">{{companyProfile[0].title}}</div>
          <div class="span"></div>
          <div class="company-desc">
            {{$deTag(companyProfile[0].content)}}
          </div>
          <a class="more" href="./about"> <u>More</u> <span class="iconfont icon-next"></span></a>
          <div class="company-numbers">
            <div class="numbers-item">
              <div class="item-num" data-target="10000" data-duration="3000"><span
                  id="company-area">{{companyProfile[0].area}}</span></div>
              <div class="item-name">Company Area</div>
            </div>
            <div class="numbers-item">
              <div class="item-num" data-target="30000" data-duration="3000"><span
                  id="company-make">{{companyProfile[0].product}}</span></div>
              <div class="item-name">Production Capacity</div>
            </div>
            <div class="numbers-item">
              <div class="item-num" data-target="1.2" data-duration="3000"><span
                  id="company-invest">{{companyProfile[0].invest}}</span></div>
              <div class="item-name">Equipment Investment</div>
            </div>
          </div>
        </div>
        <div class="r video-item">
          <video class="item-video" autoplay controls>
            <source
              src="https://video-1251283048.cos.ap-guangzhou.myqcloud.com/cailongIntroductionVideo/clIntroductVideo~1.mp4"
              type="video/mp4">
          </video>
          <div class="playbtn"></div>
          <div class="mask"></div>
        </div>
      </div>
      <div class="index-card">
        <div class="card-title container">Popular Products
          <router-link class="more" to="/products">more<span class="iconfont icon-next"></span></router-link>
        </div>
        <div class="tabs">
          <ul class="tab-titles">
            <li class="tab-title" :class="{active:activeTab===index}" v-for="(group,index) in groupedData"
              @mouseover="activeTab = index">
              {{group.categoryName}}
            </li>
          </ul>
          <div class="tab-content container">
            <div class="child " :class="{hidden:activeTab!==index}" v-for="(group,index) in groupedData">
              <ul class="list_view">
                <li v-for="item in group.products" :key="item.id">
                  <router-link :to="{name:'productDetail',params:{id:item.productId}}">
                    <div class="item-pic">
                      <div class="item-pic-view">
                        <img :src="item.imageUrl[0]" alt="pic">
                      </div>
                    </div>
                    <div class="item-text">{{item.productName}}</div>
                  </router-link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="index-card certificate">
        <div class="card-title container">{{companyProfile[1].title}}</div>
        <div class="card-desc" v-html="companyProfile[1].content"></div>
        <div class="certificate-banner">
          <div class="cert_banner swiper-container-initialized swiper-container-horizontal">
            <swiper :options="certificateSwiper">
              <swiper-slide v-for="(item,index) in homeSwiper[0].image_cer" :key="index">
                <a href="">
                  <img :src="item" />
                </a>
              </swiper-slide>
            </swiper>
            <div class="swiper-pagination"></div>
          </div>
        </div>
        <div class="cert_menu container">
          <div class="menu-item" v-for="(item,index) in homeSwiper[0].image_aut">
            <img :src="item" />
          </div>
        </div>
        <div class="card-desc">Certified by ISO9001-2015, QS certification, and complies with FDA standards</div>
      </div>
      <div class="index-card">
        <div class="card-title container">{{companyProfile[2].title}}</div>
        <div class="partners container">
          <div class="partners_banner swiper-container-initialized swiper-container-horizontal">
            <swiper :options="partnerSwiper">
              <swiper-slide v-for="(item,index) in homeSwiper[0].image_par" :key="index">
                <img :src="item" alt="">
              </swiper-slide>
            </swiper>
          </div>
        </div>

      </div>
      <div class="container research">
        <div class="research-top">
          <div class="l">
            <div class="l-title">
              <span></span>{{companyProfile[3].title}}
            </div>
            <div class="l-desc">{{companyProfile[3].content}}</div>
          </div>
          <div class="r">
            <div class="r-item">
              <div class="item-title">管理</div>
              <div class="item-subtitle">Manage</div>
            </div>
            <div class="r-item">
              <div class="item-title">生产</div>
              <div class="item-subtitle">Produce</div>
            </div>
            <div class="r-item">
              <div class="item-title">仓储</div>
              <div class="item-subtitle">Storehouse</div>
            </div>
          </div>
        </div>
        <div class="research-bot">
          <div class="pic-list">
            <div class="pic-item">
              <img src="../assets/imgs/research_img01.png" alt="">
            </div>
            <div class="pic-item">
              <img src="../assets/imgs/research_img02.png" alt="">
            </div>
            <div class="pic-item">
              <img src="../assets/imgs/research_img03.png" alt="">
            </div>
            <div class="pic-item">
              <img src="../assets/imgs/research_img04.png" alt="">
            </div>
          </div>
          <div class="r">
            <div class="r-title">Specialized coating detection instruments</div>
            <div class="r-subtitle">Specialized Instruments, Professional Technology</div>
            <div class="text-list">
              <div class="text-item">
                <div class="text-icon">
                  <img src="../assets/imgs/shape01.png" alt="">
                </div>
                <div class="text-text">Experienced technical personnel</div>
              </div>
              <div class="text-item">
                <div class="text-icon">
                  <img src="../assets/imgs/shape01.png" alt="">
                </div>
                <div class="text-text">Specialized services help customers create higher value</div>
              </div>
              <div class="text-item">
                <div class="text-icon">
                  <img src="../assets/imgs/shape01.png" alt="">
                </div>
                <div class="text-text">After-sales service team</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="container quality">
        <div class="quality-title">
          <span></span> {{companyProfile[4].title}}
        </div>
        <div class="quality-list">
          <a class="quality-item">
            <div class="item-pic">
              <div class="item-pic-view">
                <img src="../assets/imgs/quality_img01.png" alt="">
              </div>
            </div>
            <div class="item-text">Aluminum layer (metal layer) thickness test</div>
          </a>
          <a class="quality-item">
            <div class="item-pic">
              <div class="item-pic-view">
                <img src="../assets/imgs/quality_img02.png" alt="">
              </div>
            </div>
            <div class="item-text">High temperature retort test</div>
          </a>
          <a class="quality-item">
            <div class="item-pic">
              <div class="item-pic-view">
                <img src="../assets/imgs/quality_img03.png" alt="">
              </div>
            </div>
            <div class="item-text">Light transmittance / haze test</div>
          </a>
        </div>
      </div>
      <div class="advantage">
        <div class="advantage_bg">
          <img src="../assets/imgs/bg.png" alt="">
        </div>
        <div class="advantage-list container">
          <div class="list-item">
            <div class="item-text">
              guangdong cailong new material co., ltd. was founded in 2006. Mr. Lin, the founder of the company, has
              worked in FSPG for more than 20 years and has rich industrial experience. He is currently the Executive
              Vice Chairman of the Metalized Film Industry of China Plastics Processing Industry Association.
            </div>
            <div class="item-icon">
              <img src="../assets/imgs/ico01.png" alt="">
            </div>
          </div>
          <div class="list-item">
            <div class="item-icon">
              <img src="../assets/imgs/ico04.png" alt="">
            </div>
            <div class="item-text">
              Won the "National High-tech Zone Annual Leading Enterprise", "National High-tech Zone Annual Gazelle
              Enterprise" and obtained multiple national patents. Participated in the formulation of the national
              standard "General Quality Rules for Oxide Barrier Transparent Plastic Composite Films and Bags for Food
              Packaging" GB/T40266-2021, and is also the leading unit in formulating the industry standard "Transparent
              Aluminum Oxide Coated Film for Packaging".
            </div>
          </div>
          <div class="list-item">
            <div class="item-text">
              Introduced several Japanese and German vacuum aluminum plating machines, stack collapse aluminum plating
              machines and supporting high-level slitting machines. Equipped with a Class 10,000 dust-free clean
              workshop, and passed ISO9001, ISO14001, QS certification, in compliance with FDA and MSDS standard
              requirements.
            </div>
            <div class="item-icon">
              <img src="../assets/imgs/ico02.png" alt="">
            </div>
          </div>
          <div class="list-item">
            <div class="item-icon">
              <img src="../assets/imgs/ico05.png" alt="">
            </div>
            <div class="item-text">
              Products are widely used in color printing packaging, self-adhesive labels, decorative materials, food
              packaging, pharmaceutical packaging, leather, textiles, electronics, photovoltaics, optoelectronics,
              thermal insulation materials and other industries.
            </div>
          </div>
          <div class="list-item">
            <div class="item-text">
              With solid technological accumulation, adhering to the concept of technological innovation and management
              innovation, Foshan Cailong is committed to promoting the rapid development of China's high-barrier
              packaging film material industry, embarking on a new journey to comprehensively replace similar imported
              products.
            </div>
            <div class="item-icon">
              <img src="../assets/imgs/ico03.png" alt="">
            </div>
          </div>
          <div class="list-item">
            <div class="item-icon">
              <img src="../assets/imgs/ico06.png" alt="">
            </div>
            <div class="item-text">
              The global top-class WVTR/OTR tester, MOCON PERMSTRAN-W
            </div>
          </div>
        </div>
      </div>
      <div class="index_news">
        <div class="container">
          <div class="l">
            <img src="../assets/imgs/bg06.png">
          </div>
          <div class="r">
            <div class="r-top">
              <div class="r-title">
                <span></span> Enterprise Information
              </div>
              <router-link class="more" to="/news">More <span class="iconfont icon-next"></span></router-link>
            </div>
            <div class="news-list">
              <router-link :to="{name:'newsDetail',params:{id:item.id}}" v-for="(item,index) in newsList.slice(0,4)"
                :key="index">
                <div id="news6" class="list-item" style="margin-bottom: 25px;">
                  <div class="item-time">
                    <div class="item-time-day">
                      {{item.create_time.day}} <span>/</span>
                    </div>
                    <div class="item-time-more">
                      <span>{{item.create_time.year}}</span>
                      <span>{{item.create_time.month}}</span>
                    </div>
                  </div>
                  <div class="item-main">
                    <div class="item-title">{{item.title}}</div>
                    <div class="item-desc">{{$deTag(item.content)}}</div>
                  </div>
                </div>
              </router-link>
            </div>
          </div>
        </div>
      </div>
      <div class="index-card notes">
        <div class="card-title container">Online Message</div>
        <div class="notes-form container">
          <div class="two-input">
            <div class="form-input">
              <div class="form-input-label">
                <span class="iconfont icon-name"></span>
                <span>Name：</span>
              </div>
              <div class="form-input-value">
                <input type="text" v-model="btnForm.name">
              </div>
            </div>
            <div class="form-input">
              <div class="form-input-label">
                <span class="iconfont icon-phone"></span>
                <span>Phone (Required)：</span>
              </div>
              <div class="form-input-value">
                <input type="text" v-model="btnForm.phone">
              </div>
            </div>
          </div>
          <div class="form-input">
            <div class="form-input-label">
              <span class="iconfont icon-mail"></span>
              <span>Email：</span>
            </div>
            <div class="form-input-value">
              <input type="text" v-model="btnForm.email">
            </div>
          </div>
          <div class="form-textarea">
            <div class="form-input-label">
              <span class="iconfont icon-note"></span>
              <span>Content：</span>
            </div>
            <div class="form-textarea-value">
              <textarea name="" id="" cols="30" rows="4" v-model="btnForm.content"></textarea>
            </div>
          </div>
          <a href="javascript:;" class="form-submit" @click="btnSubmit()">
            Free Sample Request
          </a>
        </div>
      </div>
    </div>
    <div id="backTop">
      <span class="iconfont icon-fanhuijiantou-copy"></span>
    </div>
  </div>
</template>
<script>
  export default {
    data() {
      return {
        swiperImage: [require('@/assets/imgs/banner01En.jpg'), require('@/assets/imgs/banner01En.jpg'), require('@/assets/imgs/banner01En.jpg')],
        homeSwiper: [],
        companyProfile: [],
        productHotList: [],
        groupedData: [],
        newsList: [],
        btnForm: {},
        activeTab: null,
        certificateSwiper: {
          loop: true,
          autoHeight: true,
          speed: 1000,
          autoplay: true,
        },
        partnerSwiper: {
          loop: true,
          autoHeight: true,
          autoPlay: true,
          speed: 1000,
          delay: 3000,
          slidesPerView: 'auto',
          autoplay: {
            disableOnInteraction: false
          },
        }
      }
    },
    mounted() {
      this.getProfile();
      this.getHotProduct();
      this.getNews();
      this.getBanner();
      this.getHomeSwiper();
      this.$nextTick(() => {
        new Swiper('.shop_banner', {
          loop: true,
          autoHeight: true,
          speed: 1000,
          autoplay: true,
          pagination: {
            el: '.shop_banner .swiper-pagination',
            clickable: true,
          },
        });

        new Swiper('.partners_banner', {
          loop: true,
          autoHeight: true,
          autoplay: true,
          speed: 1000,
          delay: 3000,
          slidesPerView: 'auto',
          autoplay: true,
        });

        new Swiper('.cert_banner', {
          loop: true,
          autoHeight: true,
          speed: 1000,
          autoplay: true,
          pagination: {
            el: '.cert_banner .swiper-pagination',
            clickable: true,
          },
        });
      });
    },
    methods: {
      //获取轮播图
      async getBanner() {
        const res = await this.$request.get("/ht/banner/getPaginate");
        if (res.data.code === 0) {
          this.swiperImage = res.data.data.data;
          this.swiperImage[0].image_fir = this.swiperImage[0].image_fir.split(',')
        }
      },
      //获取首页swiper
      async getHomeSwiper() {
        const res = await this.$request.get("/ht/homeswiper/getPaginate");
        if (res.data.code === 0) {
          this.homeSwiper = res.data.data.data;
          this.homeSwiper[0].image_cer = this.homeSwiper[0].image_cer.split(',')
          this.homeSwiper[0].image_aut = this.homeSwiper[0].image_aut.split(',')
          this.homeSwiper[0].image_par = this.homeSwiper[0].image_par.split(',')
          console.log(this.homeSwiper);
        }
      },
      //获取公司简介和各种一级标题
      async getProfile() {
        const res = await this.$request.get('/ht/Profile/getPaginate');
        if (res.data.code === 0) {
          this.companyProfile = res.data.data.data
        }
      },
      //获取热门产品
      async getHotProduct() {
        const res = await this.$request.get('/ht/HotProduct/getPaginate');
        if (res.data.code === 0) {
          this.productHotList = res.data.data.data;
          this.productHotList.forEach((item) => {
            item.image_url = item.image_url.split(',')
          })
        }
        const grouped = this.productHotList.reduce((acc, item) => {
          const categoryKey = item.category_name;
          if (!acc[categoryKey]) {
            acc[categoryKey] = {
              categoryName: item.category_name,
              products: [],
            };
          }
          acc[categoryKey].products.push({
            productName: item.product_name,
            imageUrl: item.image_url,
            id: item.id,
            productId: item.product_id
          });
          return acc;
        }, {});
        this.groupedData = Object.values(grouped);
      },
      //获取企业资讯消息
      async getNews(reload = true) {
        const res = await this.$request.post("/ht/News/getPaginate", {
          fil: 1,
          page: reload ? 1 : this.pageData.current_page,
          category_id: 1
        });

        if (res.data.code === 0) {
          this.pageData = res.data.data;
          this.newsList = res.data.data.data;
          console.log(this.newsList);
          //是否显示
          this.newsList = this.newsList.filter(item => item.category_id === 1 && item.is_show === 1);
          console.log(this.newsList);
        }
        //整理时间
        this.newsList.newsTime = this.newsList.map((item, index) => {
          let obj = {};
          let time1 = item.create_time.split(' ');
          let time = time1[0].split('-');
          obj.year = time[0];
          obj.month = time[1];
          obj.day = time[2]
          return item.create_time = obj
        })
      },
      //提交检索
      async btnSubmit() {
        this.loading = true;
        let res = await this.$request.post('/ht/online/add', this.btnForm)
        this.loading = false
        if (res.data.code === 0) {
          this.$message.success("submitted successfully")
        } else {
          this.$message.error("commit failed")
        }
      }

    },
  }
</script>
<style lang="scss">
  .el-carousel__container {
    height: 850px !important;
  }
</style>